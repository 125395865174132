import React, { useContext, useEffect } from 'react';
import { ChatContext } from './ChatContext';
import { getMarketplaceAgents } from '../services/api';
import '../styles/Marketplace.css'; 
import Web3 from 'web3';

const Marketplace = () => {
  const web3Instance = new Web3((window as any).ethereum);
  const { showMarketplace, selectedAgent, setSelectedAgent, marketplaceAgents, setMarketplaceAgents } = useContext(ChatContext);

  useEffect(() => {
    if (showMarketplace) {
      fetchMarketplaceAgents();
    }
  }, [showMarketplace]);

  const fetchMarketplaceAgents = async () => {
    try {
      const agents = await getMarketplaceAgents();
      const chainId = await web3Instance.eth.getChainId();
      const filter = agents.filter((item: any) => {
        return item.chainid === chainId
      })
      setMarketplaceAgents(filter);
    } catch (error) {
      console.error('Error fetching marketplace agents:', error);
    }
  };

  if (!showMarketplace) return null;

  return (
    <div className="marketplace">
      <h2>Agent Marketplace</h2>
      <div className="agent-grid">
        {marketplaceAgents.map((agent: any) => (
          <div key={agent.id} className="agent-card">
            <div className="agent-header">
              {agent.imageUrl && < img src={agent.imageUrl} alt={agent.name} className="agent-image" />}
              <h3>{agent.name}</h3>
            </div>
            <p className="agent-type">{agent.type}</p >
            <p className="agent-description">{agent.description}</p >
            <p className="agent-owner Hash"><strong>Owner:</strong> {agent.owner}</p >
            <p className="agent-created"><strong>Created:</strong> {new Date(agent.created_at).toLocaleDateString()}</p >
            <div className="agent-actions">
              {selectedAgent && selectedAgent.id === agent.id ? (
                <button onClick={() => setSelectedAgent(null)} className="Market-deselect-btn">Deselect Agent</button>
              ) : (
                <button onClick={() => setSelectedAgent(agent)} className="Market-select-btn">Use This Agent</button>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Marketplace;
